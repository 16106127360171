/* eslint-disable @next/next/no-img-element */
import styles from "@buddieshr/styles/homeClappy.module.scss";
import MetaHeader from "@buddieshr/components/metaHeader";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Header from "@buddieshr/components/header";
import Footer from "@buddieshr/components/footer";
import Image from "next/image";
import TextColored from "@buddieshr/components/text/textColored";
import AddToSlackButton from "@buddieshr/components/addToSlackButton";
import PageWrapper from "@buddieshr/components/templates/pageWrapper";
import { Tag } from "antd";
import Features from "@buddieshr/components/app/features";
import Benefits from "@buddieshr/components/benefits";
import Quote from "@buddieshr/components/templates/quote";
import InstallNow from "@buddieshr/components/installNow";
import GetStarted from "@buddieshr/components/app/getStarted";
import G2Logos from "@buddieshr/components/g2Logos";
import TrustedBy from "@buddieshr/components/trustedBy";
import TextUnderlined from "@buddieshr/components/text/textUnderlined";
import WatchADemoButton from "@buddieshr/components/clappy/watchADemoButton";
import { PRODUCTS } from "@buddieshr/utils/constants-products";
import PricingButtonHint from "@buddieshr/components/pricing/pricingbuttonHint";
import BuddieshrAppList from "@buddieshr/components/buddieshrAppList";
import LogoApp from "@buddieshr/components/app/logoApp";
import LovePanel from "@buddieshr/components/landing/lovePanel";

export default function Home() {
  const { t } = useTranslation();
  const router = useRouter();
  const app = PRODUCTS.find((r) => r.name === "Clappy");

  return (
    <>
      <MetaHeader
        title={t("metaTitleClappy")}
        description={t("descriptionContentClappy")}
        currentURL={`https://buddieshr.com/clappy/${
          router.locale === "en" ? "" : router.locale
        }`}
        locale={router.locale}
        pageSlug="clappy"
        currentShare="https://buddieshr.com/img/apps/clappy/share_preview_1.png"
      />
      <Header app={app} />
      <main className={styles.main} style={{ background: app.backgroundColor }}>
        <PageWrapper width={1500}>
          <div className={styles.firstSectionWrapper}>
            <Image
              className={styles.mug}
              src="/img/apps/clappy/trophy-solid.svg"
              alt="Clappy Kudos"
              width={50}
              height={50}
            />
            <Image
              className={styles.grains}
              src="/img/apps/clappy/thumbs.svg"
              alt="Clappy Kudos"
              width={50}
              height={50}
            />
            <LogoApp
              app={app}
              removeBack
              withText
              withSubtitle
              removeByBuddies
              wrapperStyle={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 36,
                maxWidth: "100%",
              }}
              style={{
                textAlign: "left",
                margin: "auto",
                border: "2px solid #0000000a",
                borderRadius: "10px",
                padding: "6px 12px",
                paddingBottom: "8px",
                transform: "scale(1.2)",
                maxWidth: "calc(100% - 40px)",
              }}
            />
            <div className={styles.tagWrapper}>
              <Tag color="green" className={styles.tag}>
                RECOGNIZE COLLEAGUES
              </Tag>
            </div>
            <h1 className={styles.mainTitle}>
              Promote <TextColored app={app}>peer recognition</TextColored> and
              broadcast your <TextColored app={app}>company values</TextColored>
            </h1>
            <div className={styles.subtitle}>
              Facilitate <TextUnderlined app={app}>authentic</TextUnderlined>{" "}
              and <TextUnderlined app={app}>meaningful</TextUnderlined>{" "}
              appreciation among your employees while bringing your company
              values to life.
            </div>
            {/* <div className={styles.ratedWrapper}>
              <Rated />
            </div> */}
            <div className={styles.actions}>
              <WatchADemoButton app={app} />
              <div style={{ maxWidth: "100%" }}>
                <AddToSlackButton app={app} />
                <div className={styles.hint}>
                  <PricingButtonHint />
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
        <PageWrapper style={{ paddingBottom: 0, paddingTop: 0 }}>
          <div className={styles.video}>
            <video
              src="/img/apps/clappy/demo.mp4"
              width={760}
              style={{
                maxWidth: "100%",
              }}
              autoPlay={true}
              muted={true}
              playsInline
              loop={true}
              preload="auto"
              alt="praise, kudos and recognitions in slack"
            />
          </div>
        </PageWrapper>
        <TrustedBy />
        <br />
        <br />
        <G2Logos />
        <PageWrapper>
          <div className={styles.benefitsWrapper}>
            <Benefits app={app} />
          </div>
          <LovePanel showStars small filterKeyword="clappy" />
        </PageWrapper>

        <div className={styles.quoteWrapper}>
          <Quote>
            “ According to Gallup research, 39% of employees feel unappreciated.{" "}
            <br />
            <br />
            As a result, they are less productive, disconnected, and always
            searching for greener pastures.”
          </Quote>
        </div>
        <PageWrapper>
          <div className={styles.featureWrapper} id="features">
            <Features app={app} />
          </div>
          <GetStarted app={app} />
          <div className={styles.picturesProductWrapper}>
            <div className={styles.picturesProductInner}>
              <Image
                width={744}
                height={883}
                className={styles.picturesProductLeft}
                src="/img/apps/clappy/screens/give-reco.png"
                alt="kudos in slack"
              />
              <Image
                width={1600}
                height={800}
                className={styles.picturesProductRight}
                src="/img/apps/clappy/screens/screen.png"
                alt="praise in slack"
              />
            </div>
          </div>
          <div className={styles.installWrapper}>
            <InstallNow
              app={app}
              gradient={app.gradient}
              title={"Ready to start a virtuous cycle?"}
              subtitle="Impact your team today. Get started now."
            />
          </div>
        </PageWrapper>
        <div>
          <BuddieshrAppList appToHide={app.slug} />
        </div>
      </main>
      <Footer app={app} />
    </>
  );
}
